<template>

  <BaseModal
      name="modal_integration_spedy"
      :title="title.header"
  >
    <b-row class="ml-1 mt-3">
      <p class="title-item-2 mb-0">01.</p>
      <p class="text-description mb-0 ml-3">
        {{ $t('seller.note_center.text_2600') }}
      </p>
    </b-row>
    <b-row class="ml-1 mt-3 pr-3" v-if="api_key">
        <b-form-input
            id="token"
            v-model="tokenIntegration"
            type="text"
        ></b-form-input>
    </b-row>
    <b-row
        v-else
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">{{ $t('seller.note_center.text_2530') }}</p>
      </b-row>
   
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" @click="returnModal"> {{ $t('seller.note_center.text_2531') }} </BaseButton>
      <BaseButton variant="black" class="ml-3" @click="onSubmit" :disabled="!(activeField && !isSubmit)">
        {{title.btnSave}}</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../BaseModal";
import TokenService from "@/services/resources/TokenService";
import NoteCenterIntegration from "@/services/resources/NoteCenterIntegration";

const service = TokenService.build();
const noteCenterIntegrationApi = NoteCenterIntegration.build();

export default {
  name: "IntegrationSpedy",
  data() {
   return {
     isSubmit: false,
     api_key: null,
     active: false,
     tokenIntegration: '',
     issueIsThis: false,
     isEdit: false
   }
  },
  comments:{
    BaseModal,
  },
  computed:{
    title: function(){
      if(this.isEdit){
        return {
          header:this.$t('seller.note_center.text_2532'),
          btnSave: this.$t('seller.note_center.text_2533')
        }
      }
      return {
        header: this.$t('seller.note_center.text_2534'),
        btnSave: this.$t('seller.note_center.text_2535')
      }
    },
    activeField: function(){
      if (this.tokenIntegration.length) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods:{
    setActive(val) {
      this.active = val;
    },
    onSubmit(){
        if(!this.tokenIntegration){
          this.$bvToast.toast(this.$t('seller.note_center.text_2536'), {
            variant: "warning",
            title: "Spedy",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return false;
        } else {
          this.isSubmit = true;
          
          noteCenterIntegrationApi.post('', {
            name: 'Spedy',
            config_integration: {
              token: this.tokenIntegration
            },
            token: this.tokenIntegration,
            'default': false
          }).then(()=>{
            this.messageSuccess(this.$t('seller.note_center.text_2601'), this.$t('seller.note_center.text_2538'))
            this.$bvModal.hide("modal_integration_spedy")
          }).finally(()=>{
            this.isSubmit = false;
          })
        }
    },
    generateKey() {
      service
          .create()
          .then((response) => {
            this.api_key = response.api_key;
            this.$bvToast.toast(this.$t('seller.note_center.text_2539'), {
              title: this.$t('seller.note_center.text_2540'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    returnModal(){
      this.$bvModal.show("modal_add_integration");
      this.$bvModal.hide("modal_integration_spedy")
    },
    getToken(){
      service.get('')
          .then(response=>{
            this.api_key = response.api_key
          });
    },
    copyValue(text, type) {
      const el = document.getElementById(type);
      el.select();
      document.execCommand("copy");
      this.messageSuccess(this.$t('seller.note_center.text_2541'), this.$t('seller.note_center.text_2542'))
      this.active = true
    },
    changeConfig(value){
      this.issueIsThis = value
    },
    messageSuccess(msg, title){
      this.$bvToast.toast(msg, {
        variant: "success",
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
      });
    }
  },
  created() {
    this.$root.$on('bv::modal::shown', () => {
      this.getToken()
      noteCenterIntegrationApi.search({
        url:'/search/name/Spedy',
        name: 'Spedy'
      }).then(response=>{
        if(response.id){
          this.active = true
          this.isEdit = true
          this.tokenIntegration = JSON.parse(response.config_integration).token
        }
      })
    })
  }

}
</script>

<style scoped>

.title-item{
  color: #2133D2;
}
.title-item-2{
  color: #2133D2;
}
.custom-width-card{
  width: 154px;
}

.text-description{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858E;
}

</style>