<template>
  <div>
    <BaseModal
      name="modal_add_integration"
      :title="$t('seller.note_center.text_2494')"
  >
    <b-row class="ml-1">
      <p class="title-item mb-0">01.</p>
      <p class="text-description mb-0 ml-3">
        {{ $t('seller.note_center.text_2495') }}
      </p>
    </b-row>
    <div class="d-flex justify-content-center">
      <b-row class="mt-4"
             v-for="dataService in dataServices"
            :key="dataService.id">
        <b-col cols="12" md="4" class="mr-1" v-if="dataService.active && dataService.name === 'SmartNotas'">
          <div>
            <b-card
                img-alt="Image"
                img-top
                tag="article"
                class="mb-2 custom-width-card"
                body-class="pl-0 pr-0 size-card-smartnotas"
                @click="openIntegrationSmartNotas"
                v-b-tooltip="{
                  title: 'SmartNotas',
                  placement: 'top',
                }"
              >
                <b-card-text class="container-logo">
                  <img src="@/assets/img/icons/smartNotas.svg" alt="" />
                </b-card-text>
              </b-card>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="ml-1"
            v-if="dataService.active && dataService.name === 'NotaZZ'"
          >
            <div>
              <b-card
                img-alt="Image"
                img-top
                tag="article"
                class="mb-2 custom-width-card"
                body-class="pl-0 pr-0"
                @click="openIntegrationNotaZZ"
                v-b-tooltip="{
                  title: 'NotaZZ',
                  placement: 'top',
                }"
              >
                <b-card-text class="container-logo">
                  <img src="@/assets/img/icons/notaZZ.svg" alt="" />
                </b-card-text>
              </b-card>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="ml-2"
            v-if="dataService.active && dataService.name === 'Enotas'"
          >
            <div>
              <b-card
                img-alt="Image"
                img-top
                tag="article"
                class="custom-width-card"
                body-class="pl-0 pr-0"
                @click="openIntegrationEnotas"
                v-b-tooltip="{
                  title: 'Enotas',
                  placement: 'top',
                }"
                >
                <b-card-text class="container-logo">
                  <img width="60" src="@/assets/img/icons/enotas.png" alt="">
                </b-card-text>
              </b-card>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="ml-2"
            v-if="dataService.active && dataService.name === 'Spedy'"
          >
            <div>
              <b-card
                img-alt="Image"
                img-top
                tag="article"
                class="custom-width-card"
                body-class="pl-0 pr-0"
                @click="openIntegrationSpedy"
                v-b-tooltip="{
                  title: 'Spedy',
                  placement: 'top',
                }"
            >
              <b-card-text class="container-logo">
                <img height="35" src="@/assets/img/icons/spedy.svg" alt="" style="margin-top: 13px;">
              </b-card-text>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" @click="cancel"> {{ $t('seller.note_center.text_2496') }} </BaseButton>
    </template>
  </BaseModal>
  <IntegrationSmartNotas/>
  <IntegrationNotaZZ />
  <IntegrationENotas />
  <IntegrationSpedy />
</div>
</template>

<script>
import NoteCenter from "@/services/resources/NoteCenter";
import BaseModal from "../../../BaseModal";
import IntegrationENotas from "./IntegrationENotas";
import IntegrationNotaZZ from "./IntegrationNotaZZ";
import IntegrationSmartNotas from "./IntegrationSmartNotas";
import IntegrationSpedy from "./IntegrationSpedy";

const apiNoteCenter = NoteCenter.build();

export default {
  name: "AddIntegration",
  components: {
    BaseModal,
    IntegrationSpedy,
    IntegrationNotaZZ,
    IntegrationENotas,
    IntegrationSmartNotas,
  },
  data() {
    return {
      dataServices: {},
    };
  },
  created() {
    this.$bvModal.show("modal_add_integration");
    this.services();
  },
  methods: {
    services() {
      apiNoteCenter.get("/service").then((response) => {
        this.dataServices = response;
      });
    },
    openIntegrationSmartNotas() {
      this.$bvModal.hide("modal_add_integration");
      this.$bvModal.show("modal_integration_smart_notas");
    },
    openIntegrationNotaZZ() {
      this.$bvModal.hide("modal_add_integration");
      this.$bvModal.show("modal_integration_notaZZ");
    },
    openIntegrationEnotas() {
      this.$bvModal.hide("modal_add_integration");
      this.$bvModal.show("modal_integration_enotas");
    },
    openIntegrationSpedy() {
      this.$bvModal.hide("modal_add_integration");
      this.$bvModal.show("modal_integration_spedy");
    },
  },
};
</script>

<style scoped>
.size-card-smartnotas {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.custom-width-card {
  width: 154px;
  height: 103px;
}

.title-item {
  color: #2a63ab;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858e;
}

.img-title-integration {
  width: 15.43px;
  height: 17.14px;
}

.container-logo {
  display: grid;
  justify-content: center;
}

.card-body {
  cursor: pointer;
}

.title-integration {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #333333;
}
</style>
