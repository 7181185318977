var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseModal',{attrs:{"name":"modal_add_integration","title":_vm.$t('seller.note_center.text_2494')},scopedSlots:_vm._u([{key:"footer",fn:function({ cancel }){return [_c('BaseButton',{attrs:{"variant":"link-info"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t('seller.note_center.text_2496'))+" ")])]}}])},[_c('b-row',{staticClass:"ml-1"},[_c('p',{staticClass:"title-item mb-0"},[_vm._v("01.")]),_c('p',{staticClass:"text-description mb-0 ml-3"},[_vm._v(" "+_vm._s(_vm.$t('seller.note_center.text_2495'))+" ")])]),_c('div',{staticClass:"d-flex justify-content-center"},_vm._l((_vm.dataServices),function(dataService){return _c('b-row',{key:dataService.id,staticClass:"mt-4"},[(dataService.active && dataService.name === 'SmartNotas')?_c('b-col',{staticClass:"mr-1",attrs:{"cols":"12","md":"4"}},[_c('div',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                  title: 'SmartNotas',
                  placement: 'top',
                }),expression:"{\n                  title: 'SmartNotas',\n                  placement: 'top',\n                }"}],staticClass:"mb-2 custom-width-card",attrs:{"img-alt":"Image","img-top":"","tag":"article","body-class":"pl-0 pr-0 size-card-smartnotas"},on:{"click":_vm.openIntegrationSmartNotas}},[_c('b-card-text',{staticClass:"container-logo"},[_c('img',{attrs:{"src":require("@/assets/img/icons/smartNotas.svg"),"alt":""}})])],1)],1)]):_vm._e(),(dataService.active && dataService.name === 'NotaZZ')?_c('b-col',{staticClass:"ml-1",attrs:{"cols":"12","md":"4"}},[_c('div',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                  title: 'NotaZZ',
                  placement: 'top',
                }),expression:"{\n                  title: 'NotaZZ',\n                  placement: 'top',\n                }"}],staticClass:"mb-2 custom-width-card",attrs:{"img-alt":"Image","img-top":"","tag":"article","body-class":"pl-0 pr-0"},on:{"click":_vm.openIntegrationNotaZZ}},[_c('b-card-text',{staticClass:"container-logo"},[_c('img',{attrs:{"src":require("@/assets/img/icons/notaZZ.svg"),"alt":""}})])],1)],1)]):_vm._e(),(dataService.active && dataService.name === 'Enotas')?_c('b-col',{staticClass:"ml-2",attrs:{"cols":"12","md":"4"}},[_c('div',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                  title: 'Enotas',
                  placement: 'top',
                }),expression:"{\n                  title: 'Enotas',\n                  placement: 'top',\n                }"}],staticClass:"custom-width-card",attrs:{"img-alt":"Image","img-top":"","tag":"article","body-class":"pl-0 pr-0"},on:{"click":_vm.openIntegrationEnotas}},[_c('b-card-text',{staticClass:"container-logo"},[_c('img',{attrs:{"width":"60","src":require("@/assets/img/icons/enotas.png"),"alt":""}})])],1)],1)]):_vm._e(),(dataService.active && dataService.name === 'Spedy')?_c('b-col',{staticClass:"ml-2",attrs:{"cols":"12","md":"4"}},[_c('div',[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                  title: 'Spedy',
                  placement: 'top',
                }),expression:"{\n                  title: 'Spedy',\n                  placement: 'top',\n                }"}],staticClass:"custom-width-card",attrs:{"img-alt":"Image","img-top":"","tag":"article","body-class":"pl-0 pr-0"},on:{"click":_vm.openIntegrationSpedy}},[_c('b-card-text',{staticClass:"container-logo"},[_c('img',{staticStyle:{"margin-top":"13px"},attrs:{"height":"35","src":require("@/assets/img/icons/spedy.svg"),"alt":""}})])],1)],1)]):_vm._e()],1)}),1)],1),_c('IntegrationSmartNotas'),_c('IntegrationNotaZZ'),_c('IntegrationENotas'),_c('IntegrationSpedy')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }