import { render, staticRenderFns } from "./IntegrationENotas.vue?vue&type=template&id=5c86edb6&scoped=true&"
import script from "./IntegrationENotas.vue?vue&type=script&lang=js&"
export * from "./IntegrationENotas.vue?vue&type=script&lang=js&"
import style0 from "./IntegrationENotas.vue?vue&type=style&index=0&id=5c86edb6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c86edb6",
  null
  
)

export default component.exports