<template>
  <div>
    <BaseModal
        name="modal_integrations"
        :title="integrations.length > 0 ? $t('seller.note_center.text_2598') : $t('seller.note_center.text_2599')"
        size="md"
    >
      <div
          class="sem-docs"
          v-if="verificationContractUser()"
      >
        <p>{{ $t('seller.note_center.text_2561') }}</p>
        <p>
          {{ $t('seller.note_center.text_2562') }}
        </p>
        <BaseButton variant="black" @click="goAccount"
        >{{ $t('seller.note_center.text_2563') }}</BaseButton
        >
      </div>
      <b-row v-else>
        <b-col cols="12" md="12" class="mb-3 d-flex">
          <p class="title-item mb-0">01.</p>
          <p class="text-description ml-2 mb-2">{{ $t('seller.note_center.text_2564') }}</p>
        </b-col>
        <b-col cols="12" md="12">
          <div
            v-if="integrations.length > 0"
            class="d-flex justify-content-center align-content-center row"
          >
            <div
              v-for="integration in integrations"
              :key="integration.id"
              class="col-md-4 d-flex justify-content-center align-content-center cursor-pointer "
              @click="integrationSelected(integration)"
            >
              <b-card
                img-alt="Image"
                img-top
                tag="article"
                class="mb-2 custom-width-card justify-content-center"
                body-class="type-display"
              >
                <b-card-text
                  v-if="integration.name === 'SmartNotas'"
                  class="container-logo mb-3"
                >
                  <img src="@/assets/img/icons/smartNotas.svg" alt="" />
                </b-card-text>

                <b-card-text
                  class="container-logo justify-content-center"
                  v-if="integration.name === 'NotaZZ'"
                >
                  <img src="@/assets/img/icons/notaZZ.svg" alt="" />
                </b-card-text>

                <b-card-text
                  class="container-logo justify-content-center"
                  v-if="integration.name === 'Enotas'"
                >
                  <img width="60" src="@/assets/img/icons/enotas.png" alt="" />
                </b-card-text>

                <b-card-text
                  class="container-logo justify-content-center"
                  v-if="integration.name === 'Spedy'"
                >
                  <img height="35" src="@/assets/img/icons/spedy.svg" alt="">
                </b-card-text>
              </b-card>
            </div>
          </div>
          <div v-else>
          <span class="descricao-checkbox">
              {{ $t('seller.note_center.text_2565') }}
          </span>
            <BaseButton variant="link-info" @click="showModalAddIntegration"> {{ $t('seller.note_center.text_2566') }} </BaseButton>
          </div>
        </b-col>
      </b-row>
      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" class="mr-3"  @click="hideModalAddIntegration"> {{ $t('seller.note_center.text_2531') }} </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "../../../BaseModal";

export default {
  name: "ListIntegration",
  props: {
    integrations: {
      required: true,
    },
    modalToReturn: {
      required: true,
    },
    user: {
      required: true,
    },
    dataConfigurationOfPaidAt: {
      required: true,
    },
  },
  comments: {
    BaseModal,
  },
  methods: {
    verificationContractUser() {
      console.log(111);
      let status = !this.checkStatus(this.user.documents, "APPROVED").length;
      if (status) {
        this.disableBnt = true;
      }
      return status;
    },
    verificationContractCompany() {
      if (!this.user.company) {
        return true;
      }
      return !this.checkStatus(this.user.company.documents, "APPROVED").length;
    },
    checkStatus(docs, status) {
      if (docs.length) {
        return docs.filter((item) => item.status == status);
      } else return [];
    },
    goAccount() {
      this.$router.push("/minha-conta");
    },
    showModalAddIntegration() {
      this.$bvModal.show("modal_add_integration");
      this.$bvModal.hide("modal_integrations");
    },
    hideModalAddIntegration() {
      this.$bvModal.hide("modal_integrations");
      this.$bvModal.show(this.modalToReturn);
    },
    integrationSelected(integration) {
      if (integration.name === "SmartNotas") {
        if (!this.user.company || this.verificationContractCompany()) {
          this.$bvModal.show("modal_legal_person_without_docs");
          return false;
        }else if(!this.dataConfigurationOfPaidAt.issue_by_legal_entity){
          this.messageWarning(this.$t('seller.note_center.text_2567'))
          return false;
        }
      }

      this.hideModalAddIntegration();
      this.$emit("integrationSelected", integration);
    },
    messageWarning(msg) {
      this.$bvToast.toast(msg, {
        variant: "warning",
        title: this.$t('seller.note_center.text_2568'),
        autoHideDelay: 8000,
        appendToast: true,
      });
      return false;
    },
  },
};
</script>

<style scoped>
.type-display {
  display: contents;
}

.size-card-smartnotas {
  display: contents;
  justify-content: center;
  align-items: flex-end;
}

.custom-width-card {
  width: 154px;
  height: 103px;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #81858e;
}
.title-item {
  color: #2a63ab;
}

.custom-width-card {
  width: 154px;
}

.container-logo {
  display: grid;
  justify-content: center;
}
</style>
